<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class='pb-5'>
          <div class="d-flex justify-content-between align-items-center px-4 pt-4">
            <h3 class="card-title font-weight-bold" style='font-size: 30px'>Facturas</h3>
            <article class="d-flex justify-content-between align-items-center">
              <vs-button @click="$router.push('NuevaFactura')">Nuevo Factura</vs-button>
            </article>
          </div>
          <div class="px-5 py-3">
            <CardTable :data="dataCards" />
          </div>
          <vs-table v-model="selected" class='px-4 py-3'>
            <template #header>
              <vs-input v-model="search" border placeholder="Buscar un Artículo" />
            </template>
            <template #thead>
              <vs-tr>
                <vs-th>
                  <vs-checkbox :indeterminate="selected.length == invoices.length" v-model="allCheck"
                    @change="selected = $vs.checkAll(selected, invoices)" />
                </vs-th>
                <vs-th sort @click="invoices = $vs.sortData($event, invoices, 'FechaDeFactura')">
                  Fecha de Factura
                </vs-th>
                <vs-th sort style='max-width: 200px;'
                  @click="invoices = $vs.sortData($event, invoices, 'FechaDeVencimiento')">
                  Fecha de Vencimiento
                </vs-th>
                <vs-th sort @click="invoices = $vs.sortData($event, invoices, 'Estado')">
                  Estado
                </vs-th>
                <vs-th sort @click="invoices = $vs.sortData($event, invoices, 'Cliente')">
                  Cliente
                </vs-th>
                <vs-th sort @click="invoices = $vs.sortData($event, invoices, 'Valor')">
                  Valor
                </vs-th>
                <vs-th>
                  Opciones
                </vs-th>
              </vs-tr>
            </template>
            <template #tbody style='background: var(--vs-background) !important; margin-top: 20px;'>
              <vs-tr :key="i" v-for="(tr, i) in $vs.getPage($vs.getSearch(invoices, search), page, max)" :data="tr"
                :is-selected="!!selected.includes(tr)" not-click-selected open-expand-only-td>
                <vs-td checkbox>
                  <vs-checkbox :val="tr" v-model="selected" />
                </vs-td>
                <vs-td>
                  {{ tr.FechaDeFactura }}
                </vs-td>
                <vs-td>
                  {{ tr.FechaDeVencimiento }}
                </vs-td>
                <vs-td>
                  {{ tr.Estado }}
                </vs-td>
                <vs-td>
                  {{ tr.Cliente }}
                </vs-td>
                <vs-td>
                  {{ tr.Valor }}
                </vs-td>
                <vs-td style="width: 150px !important">
                  <div class='d-flex'>
                    <vs-button><ion-icon name="create-outline"></ion-icon></vs-button>
                    <vs-button class='bg-danger'><ion-icon name="trash-outline"></ion-icon></vs-button>
                  </div>
                </vs-td>
              </vs-tr>
            </template>
            <template #footer>
              <vs-pagination v-model="page" :length="$vs.getLength($vs.getSearch(invoices, search), max)" />
            </template>
          </vs-table>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import CardTable from "../../../../../../components/cardTable/CardTableV2.vue";
  export default {
    components: {
      CardTable,
    },
    data() {
      return {
        editActive: false,
        edit: null,
        editProp: {},
        search: '',
        allCheck: false,
        page: 1,
        max: 5,
        active: 0,
        selected: [],
        dataCards: [
          {
            title: "Pagado",
            value: 0,
            iconName: "money-bill-1",
            color: "rgba(21, 128, 61, 1)",
            tipo: "shield",
            animated: "bounce",
          },
          {
            title: "Pendiente",
            value: 0,
            iconName: "money-bill-1",
            color: "rgba(71,136,255,1)",
            tipo: "shield",
            animated: "bounce",
          },
          {
            title: "Cancelado",
            value: 0,
            iconName: "money-bill-1",
            color: "rgba(255, 0, 0, 1)",
            tipo: "shield",
            animated: "bounce",
          }
        ],
        invoices: [
          {
            "id": "1",
            "FechaDeFactura": "2024-08-01",
            "FechaDeVencimiento": "2024-08-15",
            "Estado": "Pendiente",
            "Cliente": "ClienteA",
            "Valor": 1500.00
          },
          {
            "id": "2",
            "FechaDeFactura": "2024-08-05",
            "FechaDeVencimiento": "2024-08-20",
            "Estado": "Pagado",
            "Cliente": "ClienteB",
            "Valor": 2000.00
          },
          {
            "id": "3",
            "FechaDeFactura": "2024-08-10",
            "FechaDeVencimiento": "2024-08-25",
            "Estado": "Pendiente",
            "Cliente": "ClienteC",
            "Valor": 1800.00
          },
          {
            "id": "4",
            "FechaDeFactura": "2024-08-12",
            "FechaDeVencimiento": "2024-08-27",
            "Estado": "Pagado",
            "Cliente": "ClienteD",
            "Valor": 2200.00
          },
          {
            "id": "5",
            "FechaDeFactura": "2024-08-15",
            "FechaDeVencimiento": "2024-08-30",
            "Estado": "Pendiente",
            "Cliente": "ClienteE",
            "Valor": 1600.00
          },
          {
            "id": "6",
            "FechaDeFactura": "2024-08-18",
            "FechaDeVencimiento": "2024-09-02",
            "Estado": "Pagado",
            "Cliente": "ClienteF",
            "Valor": 1900.00
          },
          {
            "id": "7",
            "FechaDeFactura": "2024-08-20",
            "FechaDeVencimiento": "2024-09-05",
            "Estado": "Cancelado",
            "Cliente": "ClienteG",
            "Valor": 2100.00
          },
          {
            "id": "8",
            "FechaDeFactura": "2024-08-22",
            "FechaDeVencimiento": "2024-09-10",
            "Estado": "Pagado",
            "Cliente": "ClienteH",
            "Valor": 2500.00
          }
        ]
      }
    },
    created() {
      this.updateDataCardsValues();
    },
    methods: {
      updateDataCardsValues() {
        // Filtra y suma los valores de las facturas según el estado
        const pagadoTotal = this.invoices
          .filter(invoice => invoice.Estado.toLowerCase() === "pagado")
          .reduce((sum, invoice) => sum + invoice.Valor, 0);

        const pendienteTotal = this.invoices
          .filter(invoice => invoice.Estado.toLowerCase() === "pendiente")
          .reduce((sum, invoice) => sum + invoice.Valor, 0);

        const canceladoTotal = this.invoices
          .filter(invoice => invoice.Estado.toLowerCase() === "cancelado")
          .reduce((sum, invoice) => sum + invoice.Valor, 0);

        // Actualiza los valores en dataCards
        this.dataCards[0].value = pagadoTotal;
        this.dataCards[1].value = pendienteTotal;
        this.dataCards[2].value = canceladoTotal;
      }
    }
  }
</script>

<style>

  .vs-table__header,
  .vs-input,
  .vs-table__footer,
  .vs-pagination__arrow,
  .vs-pagination__arrow {
    background: var(--body-bg) !important;
  }
</style>